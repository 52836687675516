import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SECRET_KEY, URLS } from '../_config/api.config';
import { CommonService } from './common.service';
// import * as crypto from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    // secret key to encrypt the login credentails
    private secretKey: string = SECRET_KEY;
    private token = false;
    // observable user details
    // public currentUserSubject: BehaviorSubject<any>;

    // onserveable logged in status
    public loggedInStatus;

    // logged in user tpe
    // public currentUser: Observable<any>;

    // login api url
    // private loginUrl = URLS.login;

    // logout api url
    // private logoutUrl = URLS.logout;
    constructor(
        private http: HttpClient,
        private commonService: CommonService
    ) {
        // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.commonService.localStorageGet('currentUser')));
        this.loggedInStatus = new BehaviorSubject<boolean>(this.hasToken());
        // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.storage.get('currentUser')));
        // this.currentUser = this.currentUserSubject.asObservable();
    }

    //   public get currentUserValue(): any {
    //     return this.currentUserSubject.value;
    // }


    /**
     * if we have token the user is loggedIn
     * @returns {boolean}
     */
    public hasToken(): boolean {
        // return this.token;
        return !!this.commonService.localStorageGet('accessToken');
    }


    // public getAccessToken(): boolean {
    //   const isEmailVerified = this.commonService.localStorageGet('isEmailVerified');
    //   if (isEmailVerified) {
    //       return !!this.commonService.localStorageGet('accessToken');
    //   }
    // }

    /**
    *
    * @returns {Observable<T>}
    */
    public isLoggedIn() {
        return this.loggedInStatus.asObservable();
    }
    public logIn() {
        this.loggedInStatus.next(true);
    }

    public logout(token?) {

        this.loggedInStatus.next(false);
        const newToken = {
            'authorization': this.commonService.localStorageGet('accessToken')
        }
        return this.http.get<any>(URLS.logout, { headers: new HttpHeaders(Object.assign(newToken)), withCredentials: true });

        //   return this.http.get<any>(`${this.logoutUrl}`);
        // remove user from local storage to log user out
        //  this.commonService.localStorageRemove('currentUser');
        //  this.currentUserSubject.next(null);
    }
}

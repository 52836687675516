import { Component, ElementRef, OnInit, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api-data.service';
import { URLS } from '../../_config/api.config';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT, Location } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-payment-confirmation',
    templateUrl: './payment-confirmation.component.html',
    styleUrls: ['./payment-confirmation.component.scss'],
})
export class PaymentConfirmationComponent implements OnInit {

    private subscription: Subscription = new Subscription();
    sessionId: any;
    public isLoading: boolean = false;
    isPaymentConfirmed: boolean = false;
    isPaymentFailed: boolean = false;
    pdfDowloading: boolean = false;
    isFinalPdfGenerated: boolean = false;
    token: any;
    formTitle: any;
    formID;
    userId;
    isCoverPage: any;

    isModalActive = false;

    //email input variables start
    @ViewChild('emailInputElement') inputElement!: ElementRef;
    inputValue: string = '';
    suggestions: string[] = [];
    showSuggestions = false;
    tags: string[] = [];
    public sharedEmail = [];
    invalidEmail = false;
    @ViewChild('openShareModal') openShareModal;
    fileId: any;
    public fileUrl: any;
    url: string = 'https://catholiclegacy.com/';
    title: string = 'Plan your legacy with Catholic values using Catholic Legacy’s free and easy to use Will, Healthcare, and Funeral Planning tool:';


    constructor(
        private router: Router,
        private dataService: ApiService,
        private toastr: ToastrService,
        private activeRoute: ActivatedRoute,
        private _location: Location,
        public commonService: CommonService,
        @Inject(DOCUMENT) private document: any,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.activeRoute.queryParams.subscribe(params => {
            this.sessionId = params['session_id'];
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.userId = this.commonService.localStorageGet('userId');
            this.token = this.commonService.localStorageGet('accessToken');
            this.paymentConfirmation();
        }, 5000);
    }

    ngAfterViewInit() {
        const modalElement = document.getElementById('surveyModal');
        if (modalElement) {
            modalElement.addEventListener('shown.bs.modal', () => {
                this.onModalShown();
            });
            modalElement.addEventListener('hidden.bs.modal', () => {
                this.onModalHidden();
            });
        }
    }

    generatePdf() {

        const info = {
            formPageUrl: environment.webUrl + '/pdf-generation?formId=' + this.formID + '&token=' + this.token,
            formId: this.formID
        }
        // if (this.isCoverPage) {
        //     info['coverPageUrl'] = environment.webUrl + '/cover-page?formId=' + this.formID + '&token=' + this.token
        // }

        const encryptedData = this.commonService.encryptData(JSON.stringify(info));
        if (!encryptedData) {
            return;
        }
        const params = {
            data: encryptedData
        }
        this.subscription.add(this.dataService.post(URLS.genratePdf, params).subscribe(
            res => {
                if (res['code'] === 200) {
                    this.toastr.success(res['message'], 'Success');
                    this.isFinalPdfGenerated = true;
                    this.fileId = res['data'].id;
                    this.fileUrl = res['data'].location;
                    this.getSharedWith();
                }
            },
            error => {
                this.toastr.error(error['message'], 'Error');
            }
        ));
    }

    paymentConfirmation() {

        // const token = this.commonService.cookieGet('token');

        const queryParams = {
            sessionId: this.sessionId
        }
        this.subscription.add(
            this.dataService.get(URLS.paymentConfirmation, queryParams, ' ', true).subscribe(data => {
                if (data['code'] == 200) {
                    if (data.data.sessionStatus === 'complete') {
                        setTimeout(() => {
                            this.isPaymentConfirmed = true;
                            this.formID = data.data.formId;
                            this.isCoverPage = data.data.coverPageHtmlString;
                            this.formTitle = data.data.formTitle;
                            this.generatePdf();
                        }, 3000);
                    } else {
                        this.isPaymentFailed = true;
                    }
                } else {
                    this.toastr.error(data['message'], "Error");
                    // if (data['code'] == 401) {
                    //     this.toastr.error('Your donation could not be processed. Kindly verify your payment details and try again.', "Action Needed: Payment Verification");
                    // }
                }
            }, error => {
                this.toastr.error(error['message'], "Error");
            })
        )
    }

    back() {
        this._location.back();
    }

    OnMyForm() {
        this.router.navigate(['my-form-status']);
    }

    downloadPdf() {

        this.pdfDowloading = true;
        // this.router.navigate(['pdf-generation'])
        const params = {
            "formId": this.formID,
            // "status": 'Filled, Not Signed',
            "userId": this.userId
        }
        if (this.formID === "64ccdf603f11d4fb4c0174a8") {
            params['status'] = 'Completed'
        } else {
            params['status'] = 'Filled, Not Signed';
        }
        this.subscription.add(
            this.dataService.put(URLS.downloadFile, params).subscribe(data => {
                this.toastr.success("Your PDF is being downloaded", "Success")
                if (data['code'] == 200) {
                    // this.toastr.success(data['message'], "Success");
                    this.commonService.downloadFile(data['data'], data.title);
                    this.pdfDowloading = false;
                } else {
                    this.toastr.error(data['message'], "Error");
                    this.pdfDowloading = false;
                }
            }, error => {
                this.toastr.error(error['message'], "Error");
                this.pdfDowloading = false;
            })
        )
    }


    //email input
    shareModalOpen() {
        this.openShareModal.nativeElement.click();
    }
    onInputChange(event: any): void {
        this.invalidEmail = false;
        const value = event.target.value;
        this.updateSuggestions(value);
    }

    updateSuggestions(value: string): void {
        if (value) {
            this.suggestions = [value];
            this.showSuggestions = true;
        } else {
            this.suggestions = [];
            this.showSuggestions = false;
        }
    }

    selectSuggestion(suggestion: string): void {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(suggestion)) {
            this.showSuggestions = false;
            this.invalidEmail = true;
            return;
        }
        if (this.tags.indexOf(suggestion) === -1) {
            this.tags.push(suggestion);
        }
        this.inputValue = '';
        this.suggestions = [];
        this.showSuggestions = false;
        this.inputElement.nativeElement.value = '';
    }

    removeTag(tag: string): void {
        this.tags = this.tags.filter(t => t !== tag);
    }

    submitted: any;
    onShare() {
        this.submitted = true;
        const data = {
            id: this.fileId,
            emails: this.tags,
        }
        this.isLoading = true;
        this.subscription.add(
            this.dataService.post(URLS.sharedFile, data).subscribe(data => {
                if (data['code'] == 200) {
                    this.submitted = false;
                    this.isLoading = false;
                    this.tags = [];
                    this.toastr.success(data['message'], "Success");
                    // this.shareForm.reset();
                    this.getSharedWith();
                } else {
                    this.toastr.error(data['message'], "Error");
                    this.submitted = false;
                    this.isLoading = false;
                }
            }, error => {
                this.toastr.error(error['message'], "Error");
                this.submitted = false;
                this.isLoading = false;
            })
        )
    }
    getSharedWith() {
        // throw new Error('Method not implemented.');
        this.sharedEmail = [];
        this.subscription.add(
            this.dataService.get(URLS.getSharedWith + '?id=' + this.fileId).subscribe(data => {
                if (data['code'] == 200) {
                    if (data['data']) {
                        this.sharedEmail = data['data'];
                    }
                } else {
                    this.toastr.error(data['message'], "Error");
                }
            }, error => {
                this.toastr.error(error['message'], "Error");
            })
        )
    }

    copy() {
        setTimeout(() => {
            const copyText = this.document.getElementById("copyText") as HTMLInputElement;
            copyText.select();
            copyText.setSelectionRange(0, 99999)
            this.document.execCommand("copy");
            this.toastr.success("URL Copied Successfully", "Success");
        }, 500);
    }

    share(platform: string) {
        const shareUrl = this.getShareUrl(platform);
        const windowFeatures = 'width=600,height=400,menubar=no,toolbar=no,location=no,status=no';
        window.open(shareUrl, '_blank', windowFeatures);
    }

    getShareUrl(platform: string): string {
        switch (platform) {
            case 'facebook':
                return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.url)}`;
            case 'twitter':
                return `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.url)}&text=${encodeURIComponent(this.title)}`;
            case 'linkedin':
                return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(this.url)}`;
            case 'whatsapp':
                return `https://api.whatsapp.com/send/?text=${encodeURIComponent(this.title + ' ' + this.url)}`;
            default:
                return '';
        }
    }



    onModalShown() {
        this.isModalActive = true;
    }

    onModalHidden() {
        this.isModalActive = false;
    }

}
